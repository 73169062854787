<template>
  <div>
    <Header />
    <div class="container">
      <h2>Todo List</h2>
      <CreateTodo />
      <TodoAssignedTo />
      <TodoAssignedBy v-if="ploNoStf"/>
      <TodoAll v-if="ploNoStf"/>
    </div>

  </div>
</template>

<script>
import {ref} from 'vue';
import Header from '@/components/Header.vue'
import CreateTodo from '@/components/CreateTodo.vue'
import TodoAssignedTo from '@/components/TodoAssignedTo.vue'
import TodoAssignedBy from '@/components/TodoAssignedBy.vue'
import TodoAll from '@/components/TodoAll.vue'
export default {
  components: {Header,CreateTodo,TodoAssignedTo, TodoAssignedBy, TodoAll},
  setup() {
    const ploType = ref("");
    const ploNoStf = ref();
    ploType.value= localStorage.getItem("ploType");
    ploType.value !== "stf" ? ploNoStf.value = true :  ploNoStf.value = false

    return {ploNoStf}
  },
}
</script>
