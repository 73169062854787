<template>
  <div>
      <h2>Todo Assigned by you</h2>
      <Panel class="p-mb-2" :header=title :toggleable="true" :collapsed="true" v-for="{id, title, assignDate, assignedTo, content, due, status} in todosBy" :key="id">
            <template #icons>
                <Chip v-if="status !== 'completed' && Date.now() > due.toDate()" label="overdue" class="p-mr-2 p-mb-2 custom-chip-ov" />
                <Chip :label=status class="p-mr-2 p-mb-2" :class="[ (status === 'ongoing' ? 'custom-chip-on' : ''), (status === 'pending' ? 'custom-chip-pe' : ''), (status === 'completed' ? 'custom-chip-co' : '')]" />
                <router-link class="no-decor" :to="`/edittodo/${id}`">
                    <button class="p-panel-header-icon p-link p-mr-2">
                        <span class="pi pi-pencil"></span>
                    </button>
                </router-link>
                <button class="p-panel-header-icon p-link p-mr-2" @click="deleteTodo(id)">
                    <span class="pi pi-trash"></span>
                </button>
            </template>
            <div>
                <div class="p-text-right">
                    <h6>
                    On: {{assignDate.toDate().toDateString()}}<br>
                    To: {{assignedTo}}<br>
                    Due: {{due.toDate().toDateString()}}
                    </h6>
                </div>
                <h5 class="p-text-left">Task Details:</h5>
                <div class="p-text-left" v-html="content"></div>
            </div>
        </Panel>
  </div>
</template>

<script>
import Panel from 'primevue/panel';
import Chip from 'primevue/chip';
import {useLoadTodoBy, deleteTodo} from '@/fbDomain'
export default {
components: {Panel,Chip},
setup() {
    const todosBy = useLoadTodoBy()
    // console.log(todosBy);
    // var sortedKeys = Object.keys(todosBy.value).sort(function(a,b) { return todosBy.value[b].due < todosBy.value[a].due });
    // console.log(sortedKeys);
    return {todosBy, deleteTodo}
  },
}
</script>

<style>
.p-chip.custom-chip-on {
    background: var(--primary-color);
    color: var(--primary-color-text);
}
.p-chip.custom-chip-pe {
    background: var(--yellow-500);
    color: var(--primary-color-text);
}
.p-chip.custom-chip-co {
    background: var(--green-500);
    color: var(--primary-color-text);
}
.p-chip.custom-chip-ov {
    background: #ea1616;
    color: var(--primary-color-text);
}
</style>