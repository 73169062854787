<template>
<Accordion v-model:activeIndex="active">
      <AccordionTab header="Add Todo">
        <div class="text-justify">
            <form @submit.prevent="onSubmit">
            <div>
                <InputText class="p-mb-2 p-mt-2" type="text" placeholder="Title" v-model="form.title" required />
            </div>
            <div>
                <Editor v-model="form.content" editorStyle="height: 320px" placeholder="Todo Description" required/>
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="assignee">Assign To</label>
                <div>
                    <select :disabled=ploNoStf name="selval" id="assignee" @change="handleChange" v-model="form.assignedToEmail" class="p-dropdown p-component p-inputwrapper p-inputwrapper-filled p-inputwrapper-focus">
                        <!-- <option value="Select Staff" disabled>Select Staff</option> -->
                        <option v-for="staff in allUsers" :key="staff.id" :value="staff.email">
                            {{ staff.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="time12">Due Date</label>
                <!-- <Calendar id="time12" v-model="form.due" :timeOnly="true" hourFormat="12" :inline="true"/> -->
                <Datepicker id="time12" v-model="due" inputFormat="dd-MMMM-yyyy" />
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="status">Status</label>
                <div>
                    <select id="status" v-model="form.status" class="p-dropdown p-component p-inputwrapper p-inputwrapper-filled p-inputwrapper-focus">
                        <!-- <option value="Select Staff" disabled>Select Staff</option> -->
                        <option value="pending">pending</option>
                        <option value="ongoing">ongoing</option>
                        <option value="completed">completed</option>
                    </select>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-4">
                <Button label="Add Todo" type="submit"  class="p-mt-2" />
            </div>
            </form>
        </div>
      </AccordionTab>
    </Accordion>
    <transition-group name="p-message" tag="div">
        <Message v-for="msg of messages" :severity="msg.severity" :life="3000" :sticky="false" :key="msg.id">{{msg.content}}</Message>
    </transition-group>
</template>

<script>
import {createTodo, useLoadUsers} from '@/fbDomain'
// import {useLoadUsers} from '@/fbDomain'
import {reactive} from 'vue'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {ref} from 'vue';
import Message from 'primevue/message';
import Editor from 'primevue/editor';
import firebase from 'firebase'
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
// import Calendar from 'primevue/calendar';
import Datepicker from 'vue3-datepicker'


export default {
  components: {InputText, Button, Message, Editor, Accordion, AccordionTab,Datepicker},
  setup() {

      const allUsers = useLoadUsers()

    const currUserEmail = localStorage.getItem("ploUserEmail")
    const currUserName = localStorage.getItem("ploUserName")
      const messages = ref([]);
        const count = ref(0);
        const active = ref(1);
        const addMessages = () => {
            messages.value = [
                {severity: 'success', content: 'Todo Added Successfully', id: count.value++},
            ]
        };

    const form = reactive({title:'', content:'', assignedToEmail: currUserEmail, status: 'pending' })
    const assignedBy = ref()
    const assignDate = ref()
    const assignedByEmail = ref()
    let assignedTo = ref(currUserName)
    let due = ref(new Date())
    // const fmtDate = ref()
    const ploType = ref("");
    const ploNoStf = ref();
    ploType.value= localStorage.getItem("ploType");
    ploType.value !== "stf" ? ploNoStf.value = false : ploNoStf.value = true

    const handleChange = (e) =>{
        var name = e.target.options[e.target.options.selectedIndex].text;
        // console.log(name);
        assignedTo.value = name
    }

    const onSubmit = async () =>{
    // const onSubmit = () =>{
      
      await createTodo({...form, 
        assignedBy: localStorage.getItem("ploUserName"), 
        assignedByEmail: currUserEmail, 
        assignDate: firebase.firestore.Timestamp.fromDate(new Date()),
        due: firebase.firestore.Timestamp.fromDate(due.value),
        assignedTo: assignedTo.value
      })

    // fmtDate.value = Date(due.value)
    // console.log(fmtDate.value)
    // console.log( firebase.firestore.Timestamp.fromDate(due.value) )
    // console.log(due.value)
    // console.log(new Date(due))
    // console.log(new Date())
      form.title = ''
      form.content = ''
      form.assignedToEmail= currUserEmail
      assignedTo.value= currUserName
      form.status= 'pending'
      assignedBy.value = ''
      assignDate.value = ''
      assignedByEmail.value = ''
      due.value = new Date()
      active.value = 1
      addMessages()
    }


    return {form,  onSubmit, messages, count, addMessages, active, allUsers, currUserEmail, due,handleChange,ploNoStf}
  },
}
</script>
<style>

</style>
